<template>
  <v-card
    flat
    tile
    color="white"
    class="py-16 pl-128 pr-128"
  >
    <v-row
      justify="start"
      align="center"
      no-gutters
    >
      <v-flex
        xs12
        align-self-start
      >
        <router-link to="/">
          <img
            src="@/assets/images/logo/with-black.png"
            height="10%"
            width="10%"
          >
        </router-link>
      </v-flex>
    </v-row>
    <v-row
      justify="center"
      align="center"
      no-gutters
    >
      <v-form ref="forgotForm">
        <v-flex
          xs6
          mt-16
          offset-3
        >
          <AlertBox
            v-if="error"
            :message="error"
          />
          <AlertBox
            v-if="success"
            :message="success"
            color="light-green lighten-3"
          />
          <v-card-text class="text-h3 text-left pl-0 pb-1">
            {{ $t('forgot_pwd') }}
          </v-card-text>
          <v-card-text class="text-left pl-0 pt-1">
            <p class="light-text text-h6 text-warp">
              {{ $t('forgot_text') }}
            </p> 
          </v-card-text>
          <v-text-field
            v-model.trim="email"
            :rules="rules.email"
            :placeholder="$t('email_address')"
            outlined
            required
          />
          <v-btn
            large
            block
            color="primary"
            dark
            class="white--text my-4"
            @click="forgot()"
          >
            {{ $t('reset_pwd') }}
          </v-btn>
          <v-card-text class="text-subtitle-2 text-center font-weight-semibold">
            <router-link
              to="/signup"
              class="text-decoration-none black--text"
            >
              {{ $t('back') }}
            </router-link>
          </v-card-text>
        </v-flex>
      </v-form>
    </v-row>
  </v-card>
</template>

<script>
// utils
import { rules } from '@/utils/validation.js'
import i18n  from '@/utils/i18n.js'

export default {
  name: "ForgotPassword",
  data() {
    return {
      error: null,
      success: null,
      email: null,
      rules,
    }
  },
  methods: {
    async forgot() {
      this.error = null
      if (this.$refs.forgotForm.validate()) {
        try {
          await this.$store.dispatch('generate', {
            email: this.email,
            tenantId: this.$store.state.tenantId,
            type: 'reset',
          })
          this.$refs.forgotForm.reset()
          this.success = i18n.t('suucess_reset_pwd_msg')
        } catch (e) {
          this.error = e
        }
      }
    },
  },
};
</script>